import React from 'react';
import '../css/Popup.css';


const Popup = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-scroll">
          {children}
        </div>
        <button className="popup-close" onClick={onClose}>닫기</button>
      </div>
    </div>
  );
};

export default Popup;
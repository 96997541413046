import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Page1.css';

const Page1 = () => {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/select-product');
  };

//   return (
//     <div className="intro-container">
//       <div className="logo-container">
//         <img src="/imgs/ktc-logo-01.svg" alt="Nego AI Logo" className="logo" />
//       </div>
//       <h1 className="title">Nego AI 챗봇</h1>
//       <div className="description">
//         <p>이 AI 챗봇은 상품 가격 협상을 도와드립니다.</p>
//         <p>실시간으로 최적의 가격을 제안하고 협상을 진행합니다.</p>
//       </div>
//       <ul className="features">
//         <li>✓ 24시간 실시간 가격 협상</li>
//         <li>✓ 개인화된 맞춤 제안</li>
//         <li>✓ 빠르고 효율적인 거래 체결</li>
//       </ul>
//       <button onClick={handleStart} className="start-button">시작하기</button>
//     </div>
//   );
// };

return (
  <div className="intro-container">
    <div className="logo-container">
      <img src="/imgs/ktc-logo-01.svg" alt="Nego AI Logo" className="logo" />
    </div>
    <h1 className="title">Nego-Wiz</h1>
    <div className="description">
      <p>This AI chatbot helps you negotiate product prices.</p>
      <p>It offers the best prices in real-time and facilitates the negotiation process.</p>
    </div>
    <ul className="features">
      <li>✓ 24/7 real-time price negotiation</li>
      <li>✓ Personalized tailored offers</li>
      <li>✓ Fast and efficient deal closure</li>
    </ul>
    <button onClick={handleStart} className="start-button">Start</button>
  </div>
);
};

export default Page1;
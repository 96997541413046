import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Page2.css';

// const products = [
//   { id: 1, scenario: 1, name: '전기 라디에이터', company: '신일', model_id: '10545082', cost: '1,000,000', img: 'productA.jpg', image:"/imgs/productA.jpg"},
//   { id: 2, scenario: 2, name: '공장 에어컨', company: '에어렉스', model_id: 'CD-600', cost: '1,000,000', img: 'productB.jpg', image:"/imgs/productB.jpg"},
//   { id: 3, scenario: 3, name: '배풍기 송풍기 산업현장 닥트호스 세트', company: '스마토', model_id: 'SMP35', cost: '500,000', img: 'productC.jpg', image:"/imgs/productC.jpg"},
//   { id: 4, scenario: 4, name: '환풍기', company: '이노텍', model_id: 'TWP-150CG', cost: '90,000', img: 'productD.jpg', image:"/imgs/productD.jpg"}
// ];  

// const products = [
//   { id: 1, scenario: 1, name: 'Electric Radiator', company: 'Shinil', model_id: '10545082', cost: '1,000', img: 'productA.jpg', image:"/imgs/productA.jpg", title : "Existing Supplier Priority Strategy", description:"Prioritize reliable suppliers with strong post-sale support to maintain the business relationship."},
//   { id: 2, scenario: 2, name: 'Factory AC', company: 'Airex', model_id: 'CD-600', cost: '1,000', img: 'productB.jpg', image:"/imgs/productB.jpg", title : "Non-Competitive Incentive Strategy", description:"Offer a reasonable discount to less aggressive suppliers to sustain business."},
//   { id: 3, scenario: 3, name: 'Industrial Fan Set', company: 'Smato', model_id: 'SMP35', cost: '500', img: 'productC.jpg', image:"/imgs/productC.jpg", title : "Competitive Multi-Negotiation Strategy", description:"Use competitive bidding after market analysis to secure the best terms."},
//   { id: 4, scenario: 4, name: 'Ventilator', company: 'InnoTech', model_id: 'TWP-150CG', cost: '90', img: 'productD.jpg', image:"/imgs/productD.jpg", title : "Supplier Optimization Strategy", description:"Streamline supplier portfolios to enhance competition and secure optimal terms."}
// ];

const products = [
  { 
    id: 1, 
    scenario: 1, 
    name: '전기 라디에이터', 
    company: '신일', 
    model_id: '10545082', 
    cost: '1,000', 
    img: 'productA.jpg', 
    image: "/imgs/productA.jpg", 
    title: "기존 공급업체 우선 전략", 
    description: "강력한 판매 후 지원을 제공하는 신뢰할 수 있는 공급업체를 우선시하여 비즈니스 관계를 유지합니다."
  },
  { 
    id: 2, 
    scenario: 2, 
    name: '공장용 에어컨', 
    company: '에어렉스', 
    model_id: 'CD-600', 
    cost: '1,000', 
    img: 'productB.jpg', 
    image: "/imgs/productB.jpg", 
    title: "비경쟁적 인센티브 전략", 
    description: "덜 공격적인 공급업체에게 합리적인 할인을 제공하여 비즈니스를 유지합니다."
  },
  { 
    id: 3, 
    scenario: 3, 
    name: '산업용 선풍기 세트', 
    company: '스마토', 
    model_id: 'SMP35', 
    cost: '500', 
    img: 'productC.jpg', 
    image: "/imgs/productC.jpg", 
    title: "경쟁적 다중 협상 전략", 
    description: "시장 분석 후 경쟁 입찰을 통해 최상의 조건을 확보합니다."
  },
  { 
    id: 4, 
    scenario: 4, 
    name: '환풍기', 
    company: '이노텍', 
    model_id: 'TWP-150CG', 
    cost: '90', 
    img: 'productD.jpg', 
    image: "/imgs/productD.jpg", 
    title: "공급업체 최적화 전략", 
    description: "공급업체 포트폴리오를 간소화하여 경쟁을 강화하고 최적의 조건을 확보합니다."
  }
];


const Page2 = () => {
  const navigate = useNavigate();

  const handleProductSelect = (product) => {
    navigate(`/chatbot/${product.id}/${product.scenario}`, { state: { product } });
  };

  return (
    <div className="product-selection-container">
      <h1 className="page-title">상품을 선택해주세요</h1>
      {/* <h1 className="page-title">Please select a product</h1> */}
      <div className="product-grid">
        {products.map((product) => (
          <div 
            key={product.id} 
            className="product-card"
          >
            <div className="product-title">{product.title}</div>
            <div className="product-descr">{product.description}</div>
            <img src={product.image} alt={product.name} className="product-image-list" />
            <div className="product-info-list">
              <div className="product-name">{product.name}</div>
              <button 
                onClick={() => handleProductSelect(product)}
                className="select-button"
              >
                선택하기
                {/* Select */}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Page2;
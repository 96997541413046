import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
// import axios from 'axios';

import Page1 from './pages/Page1.js';
import Page2 from './pages/Page2';
import Page3 from './pages/Page3';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Page1 />} />
          <Route path="/select-product" element={<Page2 />} />
          {/* <Route path="/chatbot/:productId&:scenarioId" element={<Page3 />} /> */}
          <Route path="/chatbot/:productId/:scenarioId" element={<Page3 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
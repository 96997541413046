// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
  }
  
  .popup-scroll {
    overflow-y: auto;
    margin-bottom: 20px;
    padding-right: 10px;
  }
  
  .popup-close {
    align-self: flex-end;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-close:hover {
    background-color: #0056b3;
  }

  .msger-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .restart-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .restart-button:hover {
    background-color: #0056b3;
  }`, "",{"version":3,"sources":["webpack://./src/css/Popup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,oBAAoB;IACpB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".popup-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .popup-content {\n    background-color: white;\n    padding: 20px;\n    border-radius: 8px;\n    width: 80%;\n    max-width: 600px;\n    max-height: 80vh;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .popup-scroll {\n    overflow-y: auto;\n    margin-bottom: 20px;\n    padding-right: 10px;\n  }\n  \n  .popup-close {\n    align-self: flex-end;\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .popup-close:hover {\n    background-color: #0056b3;\n  }\n\n  .msger-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .restart-button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 8px 16px;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 14px;\n    transition: background-color 0.3s;\n  }\n  \n  .restart-button:hover {\n    background-color: #0056b3;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
